'use client';

import { Navbar, NavbarBrand, Nav, NavItem } from 'reactstrap';
import { Header as HeaderType } from '@/payload-types';
import { CMSLink } from '@/components/CMSLink';
import { Media } from '@/components/Media';
import { usePathname } from 'next/navigation';

type HeaderProps = HeaderType & {
  persistOnClickLogo?: boolean | null;
  hideHeaderButton?: boolean | null;
};

export function Header({ link, logo, persistOnClickLogo = false, hideHeaderButton = false }: HeaderProps) {
  const pathname = usePathname();

  return (
    <>
      <div className="menu-container">
        <div className="container">
          <Navbar color="faded" light expand="lg">
            <NavbarBrand href={persistOnClickLogo ? pathname : '/'} className="mr-auto">
              {logo && <Media source={logo} width={258} height={49} />}
            </NavbarBrand>
            {link?.url && !hideHeaderButton ? (
              <Nav navbar>
                <NavItem className="lastmenuli">
                  <CMSLink {...link} className="top-anchor icon cta-btn header-button" id="header-cta" />
                </NavItem>
              </Nav>
            ) : null}
          </Navbar>
        </div>
      </div>
    </>
  );
}
